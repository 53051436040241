export class BackTop {
    constructor (selecteur, options = {}) {

        this.selecteur = selecteur;
        this.options = Object.assign({
            type: "slide--bottom", // options default
            // onShow: ()=>{}
        }, options);

        // this.options.onShow()
        this.selecteur.classList.add('backTop__' + this.options.type, 'hide')
        this.selecteur.addEventListener('click', this.handleClick)
        window.addEventListener('scroll', (e) => this.handleScroll(e))
    }

    handleClick(){
        window.scrollTo({
            top: 100,
        })
    }

    handleScroll(e){
        if((document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) ) {
            this.selecteur.classList.remove("hide");
        } else {
            this.selecteur.classList.add("hide");
        }   
    }
}
