/**
 * Initializes a new instance of the `forms` function.
 *
 * @param {string} selector - The CSS selector to target the forms.
 */
export function forms(selector) {
    this.selector = selector;
    if (document.querySelectorAll(this.selector)) {
        const forms = document.querySelectorAll(this.selector);
    
        forms.forEach(element => {
            const pristine = new Pristine(element);
            element.addEventListener('submit', function(e) {
                e.preventDefault();
                const valid = pristine.validate();
            });
        });
    }
}
