

export function Loader(selector) { 
    const loader = document.querySelector(selector)
    window.addEventListener('load', () => {
        loader.style.opacity = "0"
        loader.style.zIndex = "-50"
        loader.style.transition = "all .7s ease"
        setTimeout(() => {
            loader.remove()
        },'2000')
    });

}