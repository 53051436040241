
import preline from "preline";
import { forms } from "./forms";
import { jarallax } from "jarallax";
import { Animation } from "./animation";
import { Loader } from "./loader";
import { BackTop } from "./backTop";
import Anchor from "./anchor";

// import.meta.glob([
//     './images/**'
// ]);


if (document.querySelector('#loader')) {
    new Loader('#loader');
}

if (document.querySelector('#topButton')) {
    const backTop = new BackTop(document.querySelector('#topButton'), {
        type: "slide--bottom"  // slide--bottom, slide--right, zoom, fade
    })
}

let animateDefault = new Animation('fade');
let animateRight = new Animation('fade-right', {
    animation: 'fade-right'
});
let animateLeft = new Animation('fade-left', {
    animation: 'fade-left'
});
let animateBottom = new Animation('fade-down', {
    animation: 'fade-down'
});

if (document.querySelector('.form')) {
    let initFormContact = new forms('form')
}

if ((window.location.pathname === '/home') || (window.location.pathname === '/')) {
    const menu = document.querySelector('.menu')
    menu.style.display = 'none'
}

if (window.location.pathname === '/dev') {
    
    if (document.querySelectorAll('.splide').length > 0) {
        new Splide('#slider__image', {
            type : 'loop',
            autoplay: true,
            arrows: false
            },{
            classes: {
                pagination : 'splide__pagination',
                page : 'splide__pagination__page',
            },
        }).mount();
        
        new Splide( '#text-slider-image' , {
            type : 'loop',
            autoplay: true,
            arrows: false
            },{
            classes: {
                pagination : 'splide__pagination',
                page : 'splide__pagination__page',
            },
        }).mount();
        
        new Splide( '#text-slider-image-reverse' , {
            type : 'loop',
            autoplay: true,
            arrows: false
            },{
            classes: {
                pagination : 'splide__pagination',
                page : 'splide__pagination__page',
            },
        }).mount();
    }

    const parallax = document.querySelectorAll(".jarallax");
    if (parallax.length > 0) {
        parallax.forEach(element => {
            element.firstElementChild.classList.add('jarallax-img');
        });
    
        // Init jarallax
        jarallax(parallax, {
            speed: 0.2
        });
    }

}

// if (document.querySelectorAll(`[data-section-name="cards-full"]`).length > 0) {
//     const sectionCardsFull = document.querySelectorAll(`.cards`)
//     sectionCardsFull.forEach(element => {
//         // const card = element.querySelectorAll('.cards-grid__items')
//         // card.forEach(element => {
//         //     console.log(element.clientHeight);
//         //     const content = element.querySelector('.cards-grid__content')
//         //     content.style.transform = `translateY(${element.clientHeight / 2}px)`
//         // });
//         const cardTitle = element.querySelectorAll('a .cards-grid__content .heading')
//         const values = []
//         cardTitle.forEach(element => {
//             values.push(element.clientHeight)
//         });
//         cardTitle.forEach(element => {
//             element.style.height = `${Math.max(...values)}px`
//         });
//     });
    
// }

const links = document.querySelectorAll(`.menu .menu__item`)
links.forEach(link => {

    
    const menu = document.querySelector(`#navbar-default`)
    const icoHidden = document.querySelector(`.menu__btn .icons--hidden`)
    const icoVisible = document.querySelector(`.menu__btn .icons`)
    if (link.hash) {

        link.addEventListener('click', function () {

            menu.classList.add('hidden')
            icoHidden.style.display = 'none'
            icoVisible.style.display = 'flex'
        })
    }
});

// Gestions des ancres par rapport au menu
new Anchor('.anchor', '.menu')

// Gestions des images verticales
const imgs = document.querySelectorAll(`img:not(.images__full img)`)
imgs.forEach(element => {
    if (element.naturalHeight > element.naturalWidth) {
        element.classList.add(`image-contain`)
    }
});

// Gestions des images full reverse
const fullTextImages = document.querySelectorAll(`.full-text-image`)
fullTextImages.forEach(fullTextImage => {
    const firstEle = fullTextImage.firstElementChild;
    if (firstEle.classList.contains('flex-row-reverse')) {
        const col = firstEle.lastElementChild.firstElementChild
        col.classList.add(`ml-auto`)
    }
});

