export default function Anchor(selecteur, selectMenu) {
    const allSectionAnchor = document.querySelectorAll(selecteur)
    const menu = document.querySelector(selectMenu)

    if (menu) {
        allSectionAnchor.forEach(element => {
        
            if (element.id) {
                element.style.marginTop = `-${menu.clientHeight}px`
                element.style.paddingTop = `${menu.clientHeight}px`
            }
        });
        
    }
}


