import Aos from "aos";

export class Animation {

    public selector: string
    public animation: string
    public offset: number
    public delay: number
    public duration: number
    public easing: undefined
    public once: boolean
    public mirror: boolean
    public anchorPlacement: Aos.anchorPlacementOptions | undefined
    public disable : boolean | "mobile" | "phone" | "tablet" | (() => boolean) | undefined
    public options: any;

    constructor(selector: string, options) {
        this.selector = selector

        if (this.selector) {
            this.options = Object.assign({
                animation: 'fade-up',
                offset: 250, // décalage (en px) par rapport au point de déclenchement d'origine
                delay: 0, // valeurs de 0 à 3000, avec un pas de 50ms
                duration: 600, // valeurs de 0 à 3000, avec un pas de 50ms
                easing: 'ease', // easing par défaut pour les animations AOS
                once: false, // si l'animation ne doit se produire qu'une seule fois - lors du défilement vers le bas
                mirror: false, // si les éléments doivent être animés lorsque l'on défile devant eux
                anchorPlacement: 'top-bottom', // définit la position de l'élément par rapport à la fenêtre qui doit déclencher l'animation
                disable: false, // accepte les valeurs suivantes : "téléphone", "tablette", "mobile", booléen, expression ou fonction
            }, options)
            this.addAttribute()
            this.reverse()
            this.aos()
        }

    }

    aos() {
        Aos.init({
            /// Paramètres globaux :
            disable: this.options.disable, 
            startEvent: 'DOMContentLoaded', // nom de l'événement déclenché sur le document, sur lequel l'AM doit s'initialiser
            initClassName: 'aos-init', // classe appliquée après l'initialisation
            animatedClassName: 'aos-animate', // classe appliquée à l'animation
            
            // Paramètres qui peuvent être remplacés par des attributs `data-aos-*`:
            offset: this.options.offset, 
            delay: this.options.offset, 
            duration: this.options.duration, 
            easing: this.options.easing, 
            once: this.options.once, 
            mirror: this.options.mirror, 
            anchorPlacement: this.options.anchorPlacement,
        });
    }


    /**
     * Reverses the elements with the given selector.
     *
     * @return {void} No return value.
     */
    reverse(): void {
        const reverse = document.querySelectorAll(`.flex-row-reverse .${this.selector}`);
        if (reverse) {
            reverse.forEach(element => {
                let attribut : string | undefined
                if (element.getAttribute("data-aos")?.includes('right')) {
                    attribut = element.getAttribute("data-aos")?.replace('right', '')
                    return element.setAttribute("data-aos", `${attribut}left`);
                } else if (element.getAttribute("data-aos")?.includes('left')) {
                    attribut = element.getAttribute("data-aos")?.replace('left', '')
                    return element.setAttribute("data-aos", `${attribut}right`);
                }
            });
        }
    }


    /**
     * Adds an attribute to elements with a specific selector.
     *
     * @return {void} No return value.
     */
    addAttribute(): void {
        const animate = document.querySelectorAll(`.${this.selector}`);
        animate.forEach(element => {
            return element.setAttribute('data-aos', `${this.options.animation}`);
        })
        
    }
}
